import './index.css'
import React, { useContext } from 'react'
import { Container } from '@material-ui/core'
import Page from './components/Page'
import { AppContext } from './context/AppContextProvider'
import Header from './components/Header'
import Complete from './components/Complete'
import Footer from './components/Footer'

function App() {
  // console.log(process.env.REACT_APP_API_URL, 'ssss')
  const { currentPage, formComplete, PAGES } = useContext(AppContext)
  // console.log(currentPage, 'currentPage');
  return (
    <div className="App">
      <Header />
      <Container maxWidth="md">
        {formComplete ? (
          <Complete />
        ) : (
          <Page page={PAGES[currentPage]} index={currentPage} />
        )}
        {!formComplete ? (<Footer index={currentPage}/>) : null}
      </Container>
    </div>
  )
}

export default App
