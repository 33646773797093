// All of these constants are temporary and need to be replaced with proper environment variables.

export const GA_ID = 'UA-189023976-1'

// Google API is used for google maps. The key must be enabled for:
// * Maps
// * Places
// * Geocoding
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

export const API_URL = process.env.REACT_APP_API_URL || 'https://api.tools.aws.quickspark.com'

// export const CRM_ENDPOINT = 'https://qs-demo-proxy.herokuapp.com/api/simple-proxy-ac.php'
// export const QS_API_ENDPOINT = 'https://qs-demo-proxy.herokuapp.com/api/simple-proxy-formbackend.php'