import React, { useContext } from 'react'
import { Box, LinearProgress, makeStyles } from '@material-ui/core'
import logo from '../assets/qs-logo.png'
import { AppContext } from '../context/AppContextProvider'

const useStyles = makeStyles((theme) => ({
  phone: {
    margin: '5px 0',
    fontWeight: 'bold',
    fontSize: '20px',
  },
}))

const Header = () => {
  const { progressValue } = useContext(AppContext)
  const classes = useStyles()

  return (
    <div>
      <Box position="fixed" width="100%" zIndex={2}>
        <LinearProgress variant="determinate" value={progressValue} />
      </Box>
      <Box display="flex" paddingX={3} paddingY={2} alignItems="center">
        <Box flexGrow={1}>
          <img width="125" src={logo} alt="Quick Spark Logo" />
        </Box>
        {/* <Box flexGrow={1} textAlign="right">
          <div>Questions about the application?</div>
          <div className={classes.phone}>800-770-8107</div>
        </Box> */}
      </Box>
    </div>
  )
}

export default Header
