import isEmail from 'validator/lib/isEmail'
import isPhone from 'validator/lib/isMobilePhone'
import isInt from 'validator/lib/isInt'
import isUrl from 'validator/lib/isURL'
import isCurrency from 'validator/lib/isCurrency'
import { PlacesAPILocation } from '../components/inputs/AddressInput'

export interface SelectOption {
  name: string
  value: string
}

export interface GenericField {
  type: FieldType
  name: FieldName
  label?: string
  nameForErrors?: string
  optional?: boolean
}

export interface SelectField extends GenericField {
  options: SelectOption[]
  type: 'select'
}

export type FieldType =
  | 'text'
  | 'textLimit'
  | 'longText'
  | 'longTextWthoutLimit'
  | 'dollarValue'
  | 'address'
  | 'select'
  | 'phone'
  | 'email'
  | 'integer'
  | 'socialSecurity'
  | 'url'
  | 'months'

export interface Address {
  location: PlacesAPILocation
  street: string
  city: string
  state: string
  zip: string
}

export type Field = GenericField | SelectField

export type FieldName =
  | 'businessName'
  | 'contactFirstName'
  | 'contactLastName'
  | 'contactEmail'
  | 'contactPhoneNumber'
  | 'amountToFinance'
  | 'itemsToFinance'
  | 'userTitle'
  | 'userPercentOwnership'
  | 'userSocialSecurity'
  | 'userPersonalAddress'
  | 'legalBusinessName'
  | 'businessAddress'
  | 'businessPhoneNumber'
  | 'businessForm'
  | 'businessInMonths'
  | 'businessWebsiteOrSocialUrl'
  | 'yourStory'

type Fields = {
  [key in FieldName]: Field
}

const LOCALE = 'en-US'
const basicStringValidator = (value: string) => value && value.length >= 2
export const VALIDATORS = {
  text: basicStringValidator,
  textLimit: basicStringValidator,
  dollarValue: (value: string) => isCurrency(value),
  longText: basicStringValidator,
  longTextWthoutLimit: basicStringValidator,
  address: (value: Address) => value && value.street !== ' ',
  select: basicStringValidator,
  phone: (value: string) => isPhone(value, LOCALE),
  email: isEmail,
  integer: (value: string) => isInt(value, { max: 100 }),
  months: (value: string) => isInt(value, { max: 9999 }),
  url: isUrl,
  socialSecurity: (value: string) => {
    const re = /^\d{3}-?\d{2}-?\d{4}$/
    return value && re.test(value)
  },
}

export const FIELDS: Fields = {
  businessName: {
    type: 'text',
    name: 'businessName',
    label: 'Business Name',
  },
  // contactAddress: {
  //   type: 'text',
  //   name: 'contactAddress',
  //   label: 'Address',
  // },
  contactEmail: {
    type: 'email',
    name: 'contactEmail',
    label: 'E-Mail Address',
  },
  contactFirstName: {
    type: 'textLimit',
    name: 'contactFirstName',
    label: 'First Name',
  },
  contactLastName: {
    type: 'textLimit',
    name: 'contactLastName',
    label: 'Last Name',
  },
  contactPhoneNumber: {
    type: 'phone',
    name: 'contactPhoneNumber',
    label: 'Mobile Number',
  },
  amountToFinance: {
    type: 'dollarValue',
    name: 'amountToFinance',
    label: 'Amount to Finance',
    nameForErrors: 'Dollar Amount',
  },
  itemsToFinance: {
    type: 'longTextWthoutLimit',
    name: 'itemsToFinance',
    label: 'Items to Finance',
    nameForErrors: 'list of items to finance',
  },
  userTitle: {
    type: 'text',
    name: 'userTitle',
    label: 'Your Title',
    nameForErrors: 'Title',
  },
  userPercentOwnership: {
    type: 'integer',
    name: 'userPercentOwnership',
    label: '% Ownership',
    nameForErrors: 'Percentage',
  },
  userSocialSecurity: {
    type: 'socialSecurity',
    name: 'userSocialSecurity',
    label: 'Social Security Number',
  },
  userPersonalAddress: {
    type: 'address',
    name: 'userPersonalAddress',
    label: 'Personal Address',
  },
  legalBusinessName: {
    type: 'text',
    name: 'legalBusinessName',
    label: 'Legal Business Name',
  },
  businessAddress: {
    type: 'address',
    name: 'businessAddress',
    label: 'Business Address',
  },
  businessPhoneNumber: {
    type: 'phone',
    name: 'businessPhoneNumber',
    label: 'Business Phone Number',
  },
  businessForm: {
    type: 'select',
    name: 'businessForm',
    options: [
      {
        name: 'Sole Proprietorship',
        value: 'Sole Proprietorship',
      },
      {
        name: 'Limited Liability Corporation (LLC)',
        value: 'LLC',
      },
      {
        name: 'Partnership',
        value: 'Partnership',
      },
      {
        name: 'Corporation',
        value: 'Corporation',
      },
      {
        name: 'Non-Profit',
        value: 'Non-Profit',
      },
    ],
  },
  // businessIsStartup: {
  //   type: 'select',
  //   name: 'businessIsStartup',
  //   options: [
  //     {
  //       name: 'Yes',
  //       value: 'yes',
  //     },
  //     {
  //       name: 'No',
  //       value: 'no',
  //     },
  //   ],
  // },
  businessInMonths: {
    type: 'months',
    name: 'businessInMonths',
    label: 'Months in Business',
    nameForErrors: 'Number of Months'
  },
  businessWebsiteOrSocialUrl: {
    type: 'url',
    name: 'businessWebsiteOrSocialUrl',
    label: 'Website or Primary Social URL',
    optional: true
  },
  yourStory: {
    type: 'longText',
    name: 'yourStory',
    label: 'Tell us Your Story',
    optional: true
  },
}
