import { StandardTextFieldProps, Typography } from '@material-ui/core'
import React, { useContext, FocusEvent, ChangeEvent, useState, useRef, useEffect } from 'react'
import { AppContext } from '../context/AppContextProvider'
import { Field, SelectField } from '../spec/fields'
import PhoneInput from './inputs/PhoneInput'
import MultiSelectInput from './inputs/MultiSelectInput'
import TextInput from './inputs/TextInput'
import IntegerInput from './inputs/IntegerInput'
import SSNInput from './inputs/SSNInput'
import AddressInput from './inputs/AddressInput'
import DollarValueInput from './inputs/DollarValueInput'

interface FieldWrapperProps {
  field: Field
  autoFocus: boolean
}

type Mapper = () => JSX.Element

export interface WrappedTextFieldProps
  extends Omit<StandardTextFieldProps, 'onChange'> {
  onChange: (eventOrValue: ChangeEvent<Element> | any) => void
}

const FieldWrapper = ({ field, autoFocus }: FieldWrapperProps) => {
  const { onFieldChange, fieldValues, invalidFields } = useContext(AppContext)
  const value = fieldValues[field.name] || ''

  const [isPrestine, setIsPrestine] = useState(!value)

  const invalid = invalidFields.indexOf(field.name) !== -1

  const handleChange = (eventOrValue: ChangeEvent<Element> | any) => {
    if (eventOrValue.currentTarget) {
      onFieldChange(
        field.name,
        (eventOrValue as ChangeEvent<HTMLInputElement>).currentTarget.value
      )
    } else {
      onFieldChange(field.name, eventOrValue)
    }
    setIsPrestine(false)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsPrestine(false)
  }

  // TODO type the FC better
  const textInputCharLimit = (Component: React.FC<any>, CHARACTER_LIMIT: number = 25) => (
    <Component
      // autoFocus={autoFocus}
      helperText={
        invalid &&
        !isPrestine &&
        `Please enter a valid ${
          field.nameForErrors
            ? field.nameForErrors.toLowerCase()
            : field.label?.toLowerCase()
        }`
      }
      inputProps={{
        maxLength: CHARACTER_LIMIT
      }}
      rows={1}
      error={invalid && !isPrestine}
      label={field.label || ''}
      name={field.name}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}

    />
  )

  const textInput = (Component: React.FC<any>, multiline: boolean = false) => (
    <Component
      // autoFocus={autoFocus}
      helperText={
        invalid &&
        !isPrestine &&
        `Please enter a valid ${
          field.nameForErrors
            ? field.nameForErrors.toLowerCase()
            : field.label?.toLowerCase()
        }`
      }
      multiline={multiline}
      rows={multiline ? 4 : 1}
      error={invalid && !isPrestine}
      label={field.label || ''}
      name={field.name}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}

    />
  )

  const textareaInput = (Component: React.FC<any>, multiline: boolean = false, CHARACTER_LIMIT: number = 250) => (
    <Component
      // autoFocus={autoFocus}
      helperText={
        invalid &&
        !isPrestine &&
        `Please enterddd a valid ${
          field.nameForErrors
            ? field.nameForErrors.toLowerCase()
            : field.label?.toLowerCase()
        }` 
        || 
        `${value.length}/${CHARACTER_LIMIT}`
      }
      inputProps={{
        maxLength: CHARACTER_LIMIT
      }}
      multiline={multiline}
      rows={multiline ? 4 : 1}
      error={invalid && !isPrestine}
      label={field.label || ''}
      name={field.name}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
    />
  )

  const fieldMap: { [key: string]: Mapper } = {
    // TODO decide on whether closure or param
    address: () => textInput(AddressInput),
    dollarValue: () => textInput(DollarValueInput),
    text: () => textInput(TextInput),
    textLimit: () => textInputCharLimit(TextInput, 25),
    longText: () => textareaInput(TextInput, true),
    longTextWthoutLimit: () => textInput(TextInput, true),
    phone: () => textInput(PhoneInput),
    integer: () => textInput(IntegerInput),
    socialSecurity: () => textInput(SSNInput),
    select: () => {
      const cast = field as SelectField
      return (
        <MultiSelectInput
          name={cast.name}
          options={cast.options}
          value={value}
          onChange={handleChange}
        />
      )
    },
  }

  let mapper = fieldMap[field.type]
  if (!mapper) {
    // TODO DRY
    mapper = () => textInput(TextInput)
  }

  return <div>{mapper()}</div>
}

export default FieldWrapper
