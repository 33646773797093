import { FIELDS, GenericField } from './fields'

export interface PageDefinition {
  steps: Step[]
}

export interface Step {
  headerText: string
  headerSubtext?: string
  optional?: boolean
  fields: GenericField[]
}

export const PAGES: PageDefinition[] = [
  {
    steps: [
      {
        headerText: 'What is the name of your business?',
        headerSubtext:
          'This is your “DBA” name. We’ll ask for your legal business name later.',
        fields: [FIELDS.businessName],
      },
      {
        headerText: 'How much funding do you need financed?',
        headerSubtext:
          "Need more financing than the cost of the equipment? That’s fine. We’ll also leave room for shipping and a few extras.",
        fields: [FIELDS.amountToFinance],
      },
      {
        headerText: 'What items do you wish to finance?',
        headerSubtext: 'We can always get the exact details later. ',
        fields: [FIELDS.itemsToFinance],
      },
      {
        headerText: 'How can we contact you?',
        headerSubtext:
          'In case we get interrupted as you fill out the form, we can make sure you still get access to your application.',
        fields: [
          FIELDS.contactFirstName,
          FIELDS.contactLastName,
          FIELDS.contactEmail,
          FIELDS.contactPhoneNumber,
        ],
      },
    ],
  },
  {
    steps: [
      {
        headerText: 'What is your role? ',
        headerSubtext:
          'We want to know what your primary role is at your company.',
        fields: [
          FIELDS.userTitle,
          FIELDS.userPercentOwnership,
          FIELDS.legalBusinessName,
        ],
      },
      {
        headerText: 'Where is your business located?',
        fields: [
          FIELDS.businessAddress, 
          FIELDS.businessPhoneNumber
        ],
      },
      {
        headerText: 'What is your home address?',
        headerSubtext:
          'This verifies you for the credit application, make sure that the address is accurate.',
        fields: [
          FIELDS.userPersonalAddress
        ],
      },
      {
        headerText: 'What is your social security number?',
        headerSubtext:
          'This is required to complete your application. Don’t worry – your information on this form is secure and encrypted.',
        fields: [
          FIELDS.userSocialSecurity
        ],
      },
    ],
  },
  {
    steps: [
      {
        headerText: "What is your business's legal organization?",
        /*headerSubtext:
          'Every business type has different requirements and regulations, and we know exactly the right type of finance options for each type of business.',
        */
        fields: [FIELDS.businessForm],
      },
      {
        headerText: 'How long have you been in business?',
        headerSubtext: 'Providing this information helps us find the most competitive rate for you. Enter 0 if you have not started.',
        fields: [FIELDS.businessInMonths],
      },
      /*{
        headerText: 'How many years have you been in business?',
        headerSubtext:
          'Providing this information helps us find the most competitive rate for you.',
        fields: [FIELDS.businessYearsInBusiness],
      },*/
      {
        headerText: 'Do you have a website or a primary social media url?',
        headerSubtext:
          'Your web presence and engagement provide good points of reference in our match. But it’s okay if you don’t have one – just leave this area blank. ',
        fields: [FIELDS.businessWebsiteOrSocialUrl],
        optional: true,
      },
      {
        headerText: 'If you’d like, share a quick note',
        headerSubtext:
          'You are more than just a credit score. Tell us about what makes your business unique or what drives you to succeed.',
        fields: [FIELDS.yourStory],
        optional: true,
      },
    ],
  },
]
