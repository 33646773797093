import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { AppContext } from '../context/AppContextProvider'
import { Field, FieldName } from '../spec/fields'
import { PageDefinition } from '../spec/formInfo'
import FieldWrapper from './FieldWrapper'
import Progress from './Progress'
import VENDORS from '../spec/vendors'
import { SANS_SERIF, SERIF } from '../theme'

const useStyles = makeStyles({
  logoContainer: {
    backgroundColor: '#fff',
    // minHeight: '120px',
  },
})

interface PageProps {
  index: number
  page: PageDefinition
}

interface InputGroupHeaderProps {
  number: number
  text: string
  subText?: string
  optional?: boolean
}

interface InputGroupContentProps {
  fields: Field[]
  index: number
}

const InputGroupHeader = ({
  number,
  text,
  subText,
  optional,
}: InputGroupHeaderProps) => {
  return (
    <Box position="relative" paddingRight={8}>
      <Box marginBottom={1}>
        <Typography variant="h5">{text}</Typography>
      </Box>
      {optional && (
        <Box fontStyle="italic" marginBottom={2}>
          Optional
        </Box>
      )}
      {subText ? <Box fontSize={16}>{subText}</Box> : null}
    </Box>
  )
}

const InputGroupContent = ({ fields, index }: InputGroupContentProps) => {
  return (
    <Box>
      {fields.map((field, fieldIndex) => (
        <Box marginBottom={3} key={field.name}>
          <FieldWrapper
            field={field}
            autoFocus={index === 0 && fieldIndex === 0}
          />
        </Box>
      ))}
    </Box>
  )
}

const Page = ({ index, page }: PageProps) => {
  const {
    onBackClick,
    onSaveClick,
    invalidFields,
    vendorId,
    fieldValues,
    isSubmit,
  } = useContext(AppContext)
  const classes = useStyles()

  useEffect(() => {
    // Allow to render first
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }, 0)
  }, [index])

  
  const visibleFields: FieldName[] = []

  page.steps.forEach((step) => {
    step.fields.forEach((field) => visibleFields.push(field.name))
  })

  const pageInvalidFields = visibleFields.filter(
    (fieldName) => invalidFields.indexOf(fieldName) !== -1
  )


  const footer = (
    <Box display="flex" marginTop={6}>
      <Box flexGrow={1} onClick={onBackClick}>
        {index > 0 && <Button>Back</Button>}
      </Box>
      {isSubmit ? (<Box flexGrow={1} textAlign="right">
        <Button
          variant="contained"
          disableElevation
          color="primary"
          disabled={true}
        >
         Submitting...
        </Button>
      </Box>) :
        (<Box flexGrow={1} textAlign="right">
          <Button
            variant="contained"
            disableElevation
            color="primary"
            disabled={!!pageInvalidFields.length}
            onClick={onSaveClick}
          >
            {index == 2 ? 'Submit' : 'Save & Continue'}
          </Button>
        </Box>)
      }
    </Box>
  )

  const headerEquipment = (
    <>
      <Box marginBottom={1}>
        <Box
          color="primary.main"
          fontSize={12}
          fontFamily={SANS_SERIF}
          marginBottom={2}
        >
          EQUIPMENT SELECTED
        </Box>
        <Typography variant="h6">
          {fieldValues['itemsToFinance'].trim().indexOf('\n') === -1
            ? fieldValues['itemsToFinance']
            : 'Multiple Items'}
        </Typography>
      </Box>
      <Typography variant="h4">${fieldValues['amountToFinance']}</Typography>
    </>
  )

  const header = (
    <Box marginBottom={4}>
      <Box marginBottom={4}>
        <Box fontFamily={SERIF} fontSize={[24, 24, 34]} fontWeight="bold">
          Your next big business opportunity starts here
        </Box>
      </Box>
      <Box
        paddingBottom={4}
        display="flex"
        flexDirection={['column', 'column', 'row']}
      >
        <Box
          flexGrow="1"
          fontFamily={SERIF}
          fontSize={[18, 18, 20]}
          alignSelf={['flex-start', 'flex-start', 'center']}
          marginBottom={[4, 4, 0]}
        >
          {index === 0 ? (
            <>
              {/*<Box marginBottom={4}>No Credit Hit.</Box>*/}
              <Box>
                <p>
                  Congratulations on taking the next step in growing your small business.
              </p>
                <p>
                  QuickSpark is designed to help business owners like you get financing for any equipment you need. We work with a wide variety of financial partners in order to find you low interest rates with one to four-year terms. No matter your credit history, we’ll do everything we can to find something that works for you.
              </p>
                <p>
                  The best part is - we make the whole process quick and stress-free!
              </p>
                <p>
                  Fill out the application below, send it in, and we’ll start exploring options to get this equipment into your hands.
              </p>
              </Box>
            </>
          ) : null}
        </Box>
        {index === 0 ? (
          <Box
            padding={2}
            className={classes.logoContainer}
            display="inline-table"
            alignItems="center"
            maxWidth="232px"
          >
            <img
              src={VENDORS[vendorId].logo}
              alt={VENDORS[vendorId].name}
              width="200"
            />
          </Box>
        ) : null}
      </Box>
      <Progress />
    </Box>
  )

  return (
    <Box marginY={2}>
      {header}
      {page.steps.map((step, index) => (
        <Box marginBottom={[6, 6, 8]} key={index}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputGroupHeader
                text={step.headerText}
                number={index + 1}
                subText={step.headerSubtext}
                optional={step.optional}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputGroupContent fields={step.fields} index={index} />
            </Grid>
          </Grid>
        </Box>
      ))}
      {footer}
    </Box>
  )
}

export default Page
