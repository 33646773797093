import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga';
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@emotion/react'
import {
  ThemeProvider as ThemeProviderMui,
  CssBaseline,
} from '@material-ui/core'
import AppContextProvider from './context/AppContextProvider'
import theme from './theme'
import { GA_ID } from './constants';

ReactGA.initialize(GA_ID);
ReactGA.pageview('/0' + window.location.search);

// console.log('theme on init', theme)

ReactDOM.render(
  <AppContextProvider>
    <ThemeProvider theme={theme}>
      <ThemeProviderMui theme={theme}>
        <App />
        <CssBaseline />
      </ThemeProviderMui>
    </ThemeProvider>
  </AppContextProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
