import {
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import styled from '@emotion/styled'
import React, { ChangeEventHandler } from 'react'
import { SelectOption } from '../../spec/fields'

const StyledFormControlLabel = styled(
  FormControlLabel
)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.action.disabled};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  height: 56px;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  width: 100%;

  &:hover {
    border-color: ${({ theme }) => theme.palette.action.active};
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

interface QSMultiSelectProps {
  name: string
  options: SelectOption[]
  value: string
  onChange: ChangeEventHandler
}

interface OptionButtonProps {
  option: SelectOption
}

const OptionButton = ({ option }: OptionButtonProps) => {
  return (
    <StyledFormControlLabel
      value={option.value}
      control={<Radio />}
      label={option.name}
    />
  )
}

const MultiSelectInput = ({
  name,
  onChange,
  options,
  value,
}: QSMultiSelectProps) => {
  const isBoolean = options.length === 2
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('sm'));

  const content = isBoolean ? (
    <Grid container spacing={isMedium ? 0 : 3}>
      {options.map((option) => (
        <Grid item xs={12} md={6} key={option.value}>
          <OptionButton option={option} />
        </Grid>
      ))}
    </Grid>
  ) : (
    options.map((option) => (
      <OptionButton
        option={option}
        key={option.value}
      />
    ))
  )
  return (
    <div>
      <RadioGroup
        aria-label="quiz"
        name="quiz"
        value={value}
        onChange={onChange}
      >
        {content}
      </RadioGroup>
    </div>
  )
}

export default MultiSelectInput
