import { createMuiTheme } from '@material-ui/core/styles'
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography'


export const SANS_SERIF = ['PT Sans', 'sans-serif'].join(',')
export const SERIF = ['PT Serif', 'sans-serif'].join(',')

const HEADING_STYLES: TypographyStyleOptions = {
  fontWeight: 'bold',
  fontFamily: SERIF
}

const theme = createMuiTheme({
  typography: {
    fontFamily: SANS_SERIF,
    h1: HEADING_STYLES,
    h2: HEADING_STYLES,
    h3: HEADING_STYLES,
    h4: HEADING_STYLES,
    h5: HEADING_STYLES,
    h6: HEADING_STYLES,
  },
  palette: {
    primary: {
      main: '#E65100',
    },
    // secondary: {
    //   main: colors.blue1,
    // },
    // text: {
    //   primary: 'rgba(255,255,255,0.9)',
    // },
    // error: {
    //   main: red.A400,
    // },
    background: {
      default: '#FFF3E0'
    },
  },
})

export default theme
