import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import TextInput from './TextInput'
import { WrappedTextFieldProps } from '../FieldWrapper'
import { Address } from '../../spec/fields'
import { GOOGLE_API_KEY } from '../../constants'

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

let autocompleteService: any = null
let geocoderService: any = null

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

export interface PlacesAPILocation {
  description: string
  structured_formatting: {
    main_text: string
    secondary_text: string
    main_text_matched_substrings: [
      {
        offset: number
        length: number
      }
    ]
  }
}

interface GeocoderResult {
  address_components: [
    {
      long_name: string
      short_name: string
      types: string[]
    }
  ]
  formatted_address: string
}

const autoComplete = throttle(
  (input: string, callback: (results?: PlacesAPILocation[]) => void) => {
    // const request = Object.assign({}, request, { types: ['geocode'] })
    const request = { input }

    autocompleteService.getPlacePredictions(request, callback)
  },
  200
)

const fetchGeocode = (
  address: string,
  callback: (results: GeocoderResult[]) => void
) => {
  const request = { address }
  geocoderService.geocode(request, callback)
}

const makeAddress = (
  geocoderResult: GeocoderResult,
  location: PlacesAPILocation
): Address => {
  
  const findByType = (typeName: string) =>
    geocoderResult.address_components.find(
      (comp) => comp.types.indexOf(typeName) !== -1
    )
  const streetAddress = location.structured_formatting.main_text
  const street_number = findByType('street_number')?.short_name || ''
  const route = findByType('route')?.long_name || ''
  const city = findByType('locality')?.long_name || ''
  const state = findByType('administrative_area_level_1')?.short_name || ''
  const zip = findByType('postal_code')?.long_name || ''

  return {
    location,
    street: `${street_number} ${route}`,
    city,
    state,
    zip,
  }
}

interface AddressInputProps extends WrappedTextFieldProps {
  value: Address | undefined
}

const AddressInput = ({ onChange, value, ...props }: AddressInputProps) => {
  const classes = useStyles()
  // const [value, setValue] = React.useState<PlaceType | null>(null)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<PlacesAPILocation[]>([])
  const loaded = React.useRef(false)

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      )
    }

    loaded.current = true
  }

  React.useEffect(() => {
    let active = true

    if (!autocompleteService && (window as any).google) {
      autocompleteService = new (window as any).google.maps.places.AutocompleteService(
        {}
      )
    }

    if (!geocoderService && (window as any).google) {
      geocoderService = new (window as any).google.maps.Geocoder({})
    }

    if (!autocompleteService) {
      return undefined
    }

    if (inputValue === '') {
      // setOptions(value ? [value] : [])
      return undefined
    }

    autoComplete(inputValue, (results?: PlacesAPILocation[]) => {
      if (active) {
        let newOptions = [] as PlacesAPILocation[]

        // if (value) {
        //   newOptions = [value]
        // }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue])

  return (
    <Autocomplete
      id="google-map-demo"
      closeIcon={null}
      forcePopupIcon={false}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      // includeInputInList
      filterSelectedOptions
      value={value && value.location}
      onChange={(event: any, newValue: PlacesAPILocation | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        if (newValue) {
          fetchGeocode(newValue?.description, (result: GeocoderResult[]) => {
            // console.log('RESULT', result)
            if (result[0]) {
              const address = makeAddress(result[0], newValue)
              // console.log(address, 'makeAddress')
              onChange(address)
            }
          })
        }
        // setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          {...props}
          value={value && value.location.description}
        />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        )

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

export default AddressInput