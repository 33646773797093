import React, { useContext, useEffect } from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import Progress from './Progress'
import VENDORS from '../spec/vendors'
import { AppContext } from '../context/AppContextProvider'
import { SERIF } from '../theme'

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    backgroundColor: '#fff',
    minHeight: '120px',
  },
  link: {
    color: theme.palette.text.primary,
  },
}))

const Complete = () => {
  const classes = useStyles()
  const { fieldValues, vendorId } = useContext(AppContext)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const header = (
    <Box marginBottom={4}>
      <Box
        paddingBottom={4}
        display="flex"
        flexDirection={['column', 'column', 'row']}
      >
        <Box flexGrow="1">
          <Typography variant="h4">Thank you {fieldValues.contactFirstName}!</Typography>
          <Box fontWeight={400} fontFamily={SERIF} fontSize={[24, 24, 34]}>
            Your application has been submitted. Now, let us go to work for you!
          </Box>
        </Box>
        {/* <Box
          padding={2}
          className={classes.logoContainer}
          display="flex"
          alignItems="center"
          maxWidth="232px"
        >
          <img
            src={VENDORS[vendorId].logo}
            alt={VENDORS[vendorId].name}
            width="200"
          />
        </Box> */}
      </Box>
      <Progress />
    </Box>
  )
  return (
    <Box>
      {header}
      <Box fontFamily={SERIF} fontSize={20} marginBottom={10}>
        <p>
          Most of the time we’ll be back with an approval within a few hours, so hold on! We take the time to look over every application so we can send it to the right financial institutions to get you the best rate.
        </p>
        <p>
          You don’t have to leave your web page open or bookmark your shopping cart – we’ve got all your information saved. We can also make any changes before everything is said and done.  
        </p>
        <p>
          Once you’re approved, we’ll call or email immediately with the good news. Stay tuned!
        </p>
      </Box>
      <Box color="primary.main" fontSize={32} marginBottom={8}>
        NEXT STEPS
      </Box>

      <Grid container>
        <Grid item xs={12} md={6}>
          <Box marginBottom={1}>
            <Typography variant="h5">Prepare your documents</Typography>
          </Box>
          <Box fontSize={16} marginBottom={8}>
            We’ll need some other documents to complete your application, such as proof of ownership, proof of business address, and driver’s license. 
          </Box>

          {/*
          <Box marginBottom={1}>
            <Typography variant="h5">Call us with any questions</Typography>
          </Box>
          <Box fontSize={16} marginBottom={8}>
            <a href="tel:+1-800-770-8107" className={classes.link}>1-800-770-8107</a> or you can email <a href={"mailto:info@quickspark.com"} className={classes.link}>info@quickspark.com</a>
          </Box>
          */}

          <Box marginBottom={1}>
            <Typography variant="h5">
              Start thinking about the money you’re saving
            </Typography>
          </Box>
          <Box fontSize={16}>
            With your equipment financed, you’ll free up all the loose cash to save for rainy days or reinvest back into your business. 
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Complete
