import styled from '@emotion/styled'
import { StandardTextFieldProps, TextField } from '@material-ui/core'
import React from 'react'

interface QSTextFieldProps extends StandardTextFieldProps {}

const StyledTextField = styled(TextField)`
  .MuiInputLabel-root {
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }

  /* Some styles are marked important to prevent overriding by wrapping elements.
     Such as the Autocomplete widget.
   */
  .MuiInputBase-input {
    padding-top: 26.5px !important;
    padding-bottom: 10.5px !important;
  }

  textarea.MuiInputBase-input {
    padding-top: 6.5px !important;
    padding-bottom: 10.5px !important;
  }

  .MuiInputBase-root {
    padding-bottom: 0 !important;
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  .MuiInputBase-root legend {
    width: 0;
  }

  .MuiFormHelperText-root {
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    font-size: 16px;
  }

  .MuiInputAdornment-root {
    align-items: baseline;
    padding-bottom: 5px;
  }
`

const TextInput = ({ InputLabelProps, ...props }: QSTextFieldProps) => {
  return (
    <StyledTextField
      fullWidth={true}
      InputLabelProps={Object.assign({}, InputLabelProps || {}, {
        variant: 'filled',
      })}
      size="medium"
      variant="outlined"
      {...props}
    />
  )
}

export default TextInput
