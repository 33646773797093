interface Vendor {
  name: string
  logo: string
  APILogin: string
  APIPassword: string
  v: number
}

type Vendors = { [id: string]: Vendor }

const req = (filename: string) => {
  return require(`../assets/vendors/${filename}`).default
}

const vendors: Vendors = {
  'n3ymz3y': {
    name: 'QuickSpark Financial',
    logo: req('qs.png'),
    APILogin: 'n3ymz3y-quickspark',
    APIPassword: 'ei%9V9SfhoAubhO&o4Iq',
    v: 14,
  },
  '5bb83dn': {
    name: 'JMC Automotive',
    logo: req('jmc-automotive-equipment.jpg'),
    APILogin: '5bb83dn-jmcautoequip',
    APIPassword: 'lgFQ@je2YJ%3#!45RQ$L',
    v: 1968
  },
  'xnxsz84': {
    name: 'BestBlanks.com',
    logo: req('bestblanks.png'),
    APILogin: 'xnxsz84-bestblanks',
    APIPassword: 'HhC6w7&6^KYR0TQkpPv$',
    v: 4366
  },
  '8z74t62': {
    name: 'Valentino Pure Beauty',
    logo: req('valentino-beauty-pure.png'),
    APILogin: '8z74t62-valbeautypur',
    APIPassword: '21sI3H#AFBuZc1nDW59t',
    v: 5688
  },
  '5ndtq5z': {
    name: 'Open DTG',
    logo: req('open-dtg.png'),
    APILogin: '5ndtq5z-opendtg',
    APIPassword: '8D$QhFxH65KAaUZXxlOg',
    v: 7099
  },
  '5ndtq5z-DTF': {
    name: 'DTF Superstore',
    logo: req('dtf-super-store.png'),
    APILogin: '5ndtq5z-opendtg',
    APIPassword: '8D$QhFxH65KAaUZXxlOg',
    v: 7099
  },
  'gtyjd6g': {
    name: 'Tent and Table',
    logo: req('tent-and-table.jpg'),
    APILogin: 'gtyjd6g-tentandtable',
    APIPassword: 'O#o!Sl79T4^J0qR6@@j@',
    v: 4289
  },
  'nqd6j2b' : {
    name: 'Everlast Power',
    logo: req('everlast_logo.png'),
    APILogin: 'nqd6j2b-everlastgen',
    APIPassword: '#XIc3DSOuWRc&SV2ggXr',
    v: 1873
  },
  'bn42sdw' : {
    name: 'Ink Bed',
    logo: req('Inkbed.jpg'),
    APILogin: 'bn42sdw-inkbed',
    APIPassword: 'inY4!o7pZ&7hOcyn@9wF',
    v: 2755
  }
}

export default vendors
