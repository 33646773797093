import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
  },
}))

interface PageProps {
  index: number
}

const Footer = ({ index }: PageProps) => {
  const classes = useStyles()

  return (
    <>
    {index === 0 ? (<Box
      textAlign="justify"
      marginTop={14}
      marginBottom={10}
      width="100%"
    >
      <p>By clicking "Save & continue," I accept <a className={classes.link} href="https://quickspark.com/terms-and-conditions/" target="_blank" rel="noreferrer">QuickSpark Financial Terms</a>, 
      <a className={classes.link} href="https://quickspark.com/privacy-policy/" target="_blank" rel="noreferrer" > Privacy Policy</a> and request electronic communication.</p>
    </Box>) : null }
    {index === 2 ? (<Box
      textAlign="justify"
      marginTop={14}
      marginBottom={10}
      width="100%"
    >
      <p>By clicking "Submit," I accept <a className={classes.link} href="https://quickspark.com/credit-terms-and-conditions/" target="_blank" rel="noreferrer">QuickSpark Financial Terms</a>, 
      <a className={classes.link} href="https://quickspark.com/privacy-policy/" target="_blank" rel="noreferrer" > Privacy Policy</a> and request electronic communication.</p>
    </Box>): null}
    </>
    
    // <Box
    //   textAlign="justify"
    //   marginTop={14}
    //   marginBottom={10}
    //   width="100%"
    //   height={200}
    //   marginX="auto"
    //   overflow="scroll"
    //   bgcolor="#fff"
    //   padding={2}
    //   border="1px dashed grey"
    // >
    //   <p>By clicking "Save & continue," you consent to the Terms & Conditions of this Application.</p>
    //   <p>Terms and Conditions Statement</p>
    //   <p>All information submitted as part of your application for credit, together with any accompanying financial statements, schedules, or other materials, is submitted for the purpose of credit prequalification and is warranted to be true, correct, and complete.</p>
    //   <p>If applying for business credit, you acknowledge and affirm that one or more applicants identified is/are the business owner(s) and is/are personally liable for the business.</p>
    //   <p>You also warrant that by submitting this application for credit, any individual identified above who is either a principal, a personal guarantor or cosigner, or a sole proprietor of the credit applicant, recognizing that his or her individual credit history may be a factor in the evaluation of the application for credit, is authorizing inquiry into their credit worthiness, including but not limited to obtaining a consumer credit report, and shall hold QuickSpark Financial LLC, its assignees, agents, or nominees harmless from same.</p>
    //   <p>You understand that such investigation may include seeking information as to the background, credit, and financial responsibility of your business, its officers, principals, any personal guarantors or cosigners, and any corporate guarantors if applicable.</p>
    //   <p>The Federal Equal Credit Opportunity Act prohibits creditors from discriminating against credit applicants on the basis of race, color, religion, national origin, sex, marital status, or age (provided the applicant has the capacity to enter into the binding contract); because all or part of the applicant's income derives from any public assistance program; or because the applicant has in good faith exercised any right under the Consumer Credit Protection Act.</p>
    //   <p>If for any reason your application for credit is denied, you have the right to a written statement of the specific reasons for the denial. To obtain the statement, please write to us at the following address within 60 days from the date you are notified of our decision and we will send you a written statement of reasons for the denial of credit within 30 days of receiving your request for the statement.</p>
    //   <p>
    //     QuickSpark Financial LLC <br /> 4802 E Ray Rd #23, <br />Phoenix, AZ 85044
    //   </p>
    //   <p>Attn: Credit Operations</p>
    //   <p>If you received a separate decline notice from one of our underwriters, please contact them directly for specific information. You are consenting to receive communication from us and from or on behalf of our underwriters either via phone (including voice and text messages), in writing through postal mail, or via email. You consent that such communications may be generated by automatic dialer systems, pre-recorded voice messages, and automatically generated text messages or emails. For any calls or text messages, your cellular or mobile telephone provider may charge you according to the type of plan you carry and you are responsible for such charges. </p>
    //   <p>
    //     You also agree we may contact you at any email address(es) you have provided or may provide in the future or any phone number(s) you have provided or may provide in the future. You agree that we may record any phone calls between you and us for quality control purposes or our own legal protection. You agree that any such communication is not unsolicited for purposes of federal or state law. If you authorize a third party to communicate on your behalf with us, our agents, or our underwriters regarding your account with us, you agree and acknowledge that we may disclose information about your account to the third party.
    //   You also agree to QuickSpark Financial{' '}
    //   <a
    //     className={classes.link}
    //     href="https://quickspark.com/privacy-policy/"
    //     target="_blank"
    //     rel="noreferrer"
    //   >
    //     Privacy Policy
    //   </a>{' '}
    //   and{' '}
    //   <a
    //     className={classes.link}
    //     href="https://quickspark.com/terms-and-conditions/"
    //     target="_blank"
    //     rel="noreferrer"
    //   >
    //     Terms of Service
    //   </a>
    //   .
    //   </p>
    // </Box>
  )
}

export default Footer
