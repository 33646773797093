import { InputAdornment, InputProps, StandardTextFieldProps } from '@material-ui/core'
import React from 'react'
import TextInput from './TextInput'

const DollarValueInput = ({ ...props }: StandardTextFieldProps) => {
  const inputProps: InputProps = {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  }
  return <TextInput InputProps={inputProps} {...props}></TextInput>
}

export default DollarValueInput
