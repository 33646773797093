import React, { useState, createContext, PropsWithChildren } from 'react'
import moment from 'moment'
import axios from 'axios'
import ReactGA from 'react-ga'
import { API_URL } from '../constants'
import { FieldName, VALIDATORS, FIELDS, Address } from '../spec/fields'
import { PAGES } from '../spec/formInfo'
import VENDORS from '../spec/vendors'

declare global {
  interface Window {
    dataLayer:any;
  }
}

interface ContextState {
  currentPage: number
  fieldValues: FieldValues
  formComplete: boolean
  invalidFields: FieldName[]
  onFieldChange: (fieldName: FieldName, value: string) => void
  onSaveClick: VoidFunction
  onBackClick: VoidFunction
  PAGES: typeof PAGES
  progressValue: number
  totalInvalidFields: number
  vendorId: string,
  isSubmit: boolean,
}

export const AppContext = createContext<ContextState>({} as ContextState)

type FieldValues = {
  [key in FieldName]?: any
}

const URL_PARAMS = new URLSearchParams(window.location.search)
const getAmountToFinance = () => {
  return URL_PARAMS.get('cost') || URL_PARAMS.get('AmountToFinance') || ''
}
const getItemsToFinance = () => {
  const value =
    URL_PARAMS.get('ProductName') || URL_PARAMS.get('cartdata') || ''
  return value
    .split('pId~pn~').filter((item) => item)
    .map((item) => item.trim())
    .join('\n')
}

axios.defaults.headers.post['Content-Type'] = 'application/json';

const AppContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [formComplete, setFormComplete] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [fieldValues, setFieldValues] = useState<FieldValues>({
    amountToFinance: Number(getAmountToFinance()).toFixed(0),
    itemsToFinance: getItemsToFinance(),
  })

  const getVendorId = () => {
    return URL_PARAMS.get('vurl') || 'n3ymz3y'
  }
  
  const getWsc = () => {
    return URL_PARAMS.get('wsc') || ''
  }

  // TODO remove temp fallback value
  const [vendorId] = useState(getVendorId())

  const invalidFields = Object.entries(FIELDS)
    .filter(([fieldName, field]) => {
      if (field.optional) {
        return false
      }

      const validator = VALIDATORS[field.type]
      const invalid = !validator(fieldValues[field.name] || '')
      return invalid
    })
    .map(([fieldName, fieldValue]) => {
      return fieldName as FieldName
    })

  // console.log('fieldValues', fieldValues)
  // console.log('invalidFields', invalidFields)

  const handleFieldChange = (fieldName: FieldName, value: any) => {
    let updateObj = {
      [fieldName]: value,
    }
    setFieldValues(Object.assign({}, fieldValues, updateObj))
  }

  const handleSaveClick = () => {   
    if (currentPage === 0 ){
      window.dataLayer.push({"event":"step1complete"})
    } else if (currentPage === 1) {
      window.dataLayer.push({"event":"step2complete"})
    }
    if (currentPage < PAGES.length - 1) {
      setCurrentPage(currentPage + 1)
      ReactGA.pageview(`/${currentPage}` + window.location.search)
      // console.log('setting current page to', currentPage + 1)
    } else {
      setIsSubmit(true)
      postFieldValues(API_URL, fieldValues)
    }
  }

  const monthToYears = (months:number) => {
    return (months / 12 | 0) + "." + months % 12
  }

  const randomUniqueId = () => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  const phoneFormat = (value: string) => {
    return value.replace('(','').replace(')','').replace(' ', '').replace('-','')
  }

  const postFieldValues = (url: any, fieldValues: FieldValues) => {
  
    // const time_in_business = fieldValues?.businessInMonths / 12

    const objData:any = {
      APILogin: VENDORS[getVendorId()].APILogin,
      APIPassword: VENDORS[getVendorId()].APIPassword,
      vCode: getVendorId(),
      ipAddress: '',
      API: "Y",
      do: "apply",
      AppType: "Business",
      Populate: "N",
      OrderId: '',
      FName1: fieldValues?.contactFirstName,
      LName1: fieldValues?.contactLastName,
      SSN1: fieldValues?.userSocialSecurity,
      Address1: fieldValues?.userPersonalAddress?.street,
      City1: fieldValues?.userPersonalAddress?.city,
      State1: fieldValues?.userPersonalAddress?.state,
      Zip1: fieldValues?.userPersonalAddress?.zip,
      Phone1: phoneFormat(fieldValues?.contactPhoneNumber),
      Title1: fieldValues?.userTitle,
      OwnershipPercentage1: fieldValues?.userPercentOwnership,
      Email1: fieldValues?.contactEmail,
      BizDBA: fieldValues?.businessName,
      Mobile: '',
      wsc: getWsc(),
      terms: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      story: fieldValues?.yourStory,
      EquipmentCost: fieldValues?.amountToFinance,
      EquipmentDescription: fieldValues?.itemsToFinance,
      FinanceTerm: '',
      LeaseType: '',
      BizType: fieldValues?.businessForm,
      BizName: fieldValues?.legalBusinessName,
      BizTaxId: '',
      YearsInBiz:  monthToYears(fieldValues?.businessInMonths),
      BizCredit: '',
      BizAddress: fieldValues?.businessAddress?.street,
      BizCity: fieldValues?.businessAddress?.city,
      BizState: fieldValues?.businessAddress?.state,
      BizZip: fieldValues?.businessAddress?.zip,
      BizPhone: phoneFormat(fieldValues?.businessPhoneNumber),
      BizFax: '',
      Website: fieldValues?.businessWebsiteOrSocialUrl,
      ShipToAddress: '',
      ShipToCity: '',
      ShipToState: '',
      ShipToZip: '',
      EquipmentAddress: '',
      EquipmentCity: '',
      EquipmentState: '',
      EquipmentZip: '',
      v: VENDORS[getVendorId()].v,
      unique_id: randomUniqueId()
    }
  
    // console.log(objData, 'objData')

    const config = {
      headers: {
        'Content-Type' : 'application/json'
      }
    }

    axios.post(`${url}/application`, JSON.stringify(objData), config).then((res) => {
      const dataObj:any = JSON.parse(res.data)
      // console.log(dataObj, 'dataObj')
      // console.log(res.data, 'ok') 
      setFormComplete(true)
      GMTEvents(fieldValues, objData, 'application success', dataObj?.body?.result?.appid)
      ReactGA.pageview('/complete' + window.location.search)      
      setIsSubmit(false)
    }).catch(err => {
      // console.log(err.message, 'error - message') 
      // console.log(err.response, 'error - response') 
      GMTEvents(fieldValues, objData, 'application failed  - ' + err.message, 0)
      setFormComplete(true)
      ReactGA.pageview('/complete' + window.location.search)
      setIsSubmit(false)
    })
  }

  const handleBackClick = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1)
      ReactGA.event({
        category: 'Back Button',
        action: 'Click',
      })
      // console.log('setting current page to', currentPage - 1)
    }
  }

  const GMTEvents = (fieldValues: any, obj: any, eventMsg: string, appid: number) => {
    window.dataLayer.push({
      "event": eventMsg,
      "v": obj.v,
      "appid": appid,
      "unique_id": obj.unique_id,
      "businessName": fieldValues?.businessName,
      "contactEmail": fieldValues?.contactEmail,
      "contactFirstName": fieldValues?.contactFirstName,
      "contactLastName": fieldValues?.contactLastName,
      "contactPhoneNumber": fieldValues?.contactPhoneNumber,
      "itemsToFinance": fieldValues?.itemsToFinance,
      "userTitle": fieldValues?.userTitle,
      "userPercentOwnership": fieldValues?.userPercentOwnership,
      "userSocialSecurity": fieldValues?.userSocialSecurity,
      "userPersonalAddress": fieldValues?.userPersonalAddress.location.description,
      "legalBusinessName": fieldValues?.legalBusinessName,
      "businessAddress": fieldValues?.businessAddress.location.description,
      "businessPhoneNumber": fieldValues?.businessPhoneNumber,
      "businessForm": fieldValues?.businessForm,
      "businessYearsInBusiness": monthToYears(fieldValues?.businessInMonths),
    })
    // console.log(window.dataLayer, 'window.dataLayer')
  }

  const progressValues = [33.33, 66.66, 100]
  const progressValue = formComplete ? 100 : progressValues[currentPage]

  const contextValue: ContextState = {
    currentPage,
    fieldValues,
    invalidFields,
    formComplete,
    onBackClick: handleBackClick,
    onFieldChange: handleFieldChange,
    onSaveClick: handleSaveClick,
    PAGES,
    progressValue,
    totalInvalidFields: invalidFields.length,
    vendorId,
    isSubmit
  }

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

export default AppContextProvider
