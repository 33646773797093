import React, { useContext } from 'react'
import { Box, LinearProgress, makeStyles } from '@material-ui/core'
import { AppContext } from '../context/AppContextProvider'
import { PAGES } from '../spec/formInfo'

const useStyles = makeStyles((theme) => ({
  stepXofY: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    marginTop: '5px',
    textTransform: 'uppercase',
  },
}))

const Progress = () => {
  const classes = useStyles()
  const { currentPage, formComplete, progressValue } = useContext(AppContext)

  return (
    <Box>
      <Box>
        <LinearProgress variant="determinate" value={progressValue} />
      </Box>
      <Box className={classes.stepXofY}>
        {formComplete
          ? 'COMPLETE'
          : `Step ${currentPage + 1} of ${PAGES.length}`}
      </Box>
    </Box>
  )
}

export default Progress
