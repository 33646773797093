import { StandardTextFieldProps } from '@material-ui/core'
import React from 'react'
import InputMask from 'react-input-mask'
import TextInput from './TextInput'

const PhoneInput = ({ value, ...props }: StandardTextFieldProps) => {
  return (
    <InputMask
      mask="(999) 999-9999"
      value={value as string}
      onChange={props.onChange}
      onBlur={props.onBlur}
    >
      <TextInput {...props}></TextInput>
    </InputMask>
  )
}

export default PhoneInput